/* .calender {
    padding: 50px;
  } */
  .appointment_box{
    display: flex;
  }
  .appointment_box > .left {
    width: 35%;
    display: flex;
    flex-direction: column;
    background-color: #3384ef;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0px 0px 8px;
  }
  .appointment-form{
    width: 65%;
  }
  .appointment_box > .left >  p, h5 {
    color: white;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;
  }

  .appointment_box > .left > p{
    font-size: 13px;
  }

  .right-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
  }
  .right-header >  p{
    font-size: 16px;
    font-weight: 500;
  }
  .appointment_modal > .ant-modal-content {
    padding: 0 !important;
  }
  .appointment-form{
    padding: 20px 24px;
  }
  /* .ant-form-item {
    margin-bottom: 12px !important;
  } */
  
  .tooltip__icon{
    margin-left: 5px;
    color: #3384ef;
  }
  .fc-button-primary {
    margin-right: 8px !important;
    background-color: #3384ef !important;
    border: none !important;
    border-radius: 8px;
  }
  .fc-event-time{
    display: none !important;
  }
  .fc-list-event-time{
    display: none !important;
  }

  .appointment-tooltip {
    min-width: 250px;
    max-width: 350px;
  }

  .appointment-tooltip p {
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 1.4;
  }

  .appointment-tooltip p:last-child {
    margin-bottom: 0;
  }

  .appointment-tooltip strong {
    font-weight: 600;
  }



  .appointment-popover .ant-popover-inner {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .appointment-popover .ant-popover-title {
    font-weight: 600;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 16px;
    font-size: 14px;
  }

  .appointment-popover .ant-popover-inner-content {
    padding: 12px 16px;
  }

  /* Fix popover arrow position */
  .appointment-popover .ant-popover-arrow {
    z-index: 1;
    display: block !important;
  }

  /* Make the calendar events look better with tooltips */


  .fc-event {
    border-radius: 4px !important;
    overflow: hidden;
    position: relative; /* Ensure proper positioning context for popover */
  }

  .fc-event:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
  }



  /* Ensure popover is visible in all calendar views */
  .fc-view-harness {
    overflow: visible !important;
    z-index: 1 !important;
  }

  /* List view popover centering */
  .fc-list-table .appointment-popover,
  .list-view-popover {
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .list-view-popover .ant-popover-arrow {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  /* .fc-scroller {
    overflow: visible !important;
  } */



  /* Improve calendar day and time grid appearance */
  /* .fc-day-today {
    background-color: rgba(24, 144, 255, 0.05) !important;
  } */

  .fc-timegrid-event {
    padding: 2px 4px !important;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0 !important;
  }

  .fc-timegrid-event-harness {
    margin: 1px 0 !important;
    z-index: 1; /* Lower z-index for events */
  }

  /* Ensure popover container has proper z-index */
  .fc .fc-popover {
    z-index: 1000 !important;
  }

/* Modern Calendar UI with enhanced UX */

/* Calendar container */
/* .calender {
  max-width: 1400px;
} */

/* Calendar header styling */
.fc .fc-toolbar {
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1.5rem !important;
  padding: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.fc .fc-toolbar-title {
  font-size: 1.5rem !important;
  font-weight: 600;
  color: #333;
}


.fc-theme-standard .fc-scrollgrid {
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  overflow: hidden;
}

.fc-popover-body {
  background-color: #fff !important;
}
/* Day cell styling */
.fc-daygrid-day {
  transition: background-color 0.2s;
  cursor: pointer;
}

.fc-daygrid-day:hover {
  background-color: #f9f9f9;
}

.fc-day-today {
  background-color: rgba(24, 144, 255, 0.05) !important;
}

.fc-day-today .fc-daygrid-day-number {
  background-color: #1890ff;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
}

/* Time grid styling */
.fc-timegrid-slot {
  height: 3em !important;
  border-bottom: 1px solid #f5f5f5;
}

.fc-timegrid-slot-lane:hover {
  background-color: rgba(24, 144, 255, 0.05);
}

.fc-timegrid-now-indicator-line {
  border-color: #ff4d4f !important;
  z-index: 5;
}

.fc-timegrid-now-indicator-arrow {
  border-color: #ff4d4f !important;
  color: #ff4d4f !important;
}

/* Event styling */
.fc-event {
  border-radius: 6px !important;
  border: none !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
  margin: 1px 1px 2px 1px;
}

.fc-event:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.fc-event-main-wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 4px 8px;
}

.fc-event-title {
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4;
}

.fc-event-time {
  font-size: 11px;
  opacity: 0.8;
  font-weight: 500;
}

/* List view styling */
.fc-list-day-cushion {
  background-color: #f5f5f5 !important;
}

.fc-list-event:hover td {
  background-color: #e6f7ff !important;
}

.fc-list-event-dot {
  border-color: #1890ff !important;
}

/* Tooltip styling */
.appointment-tooltip {
  min-width: 280px;
  max-width: 350px;
}

.appointment-tooltip p {
  margin-bottom: 6px;
  font-size: 13px;
  line-height: 1.4;
}

.appointment-tooltip p:last-child {
  margin-bottom: 0;
}

.appointment-tooltip strong {
  font-weight: 600;
}

/* Ensure popover is always visible */
.appointment-popover {
  z-index: 1000 !important;
}

.appointment-popover .ant-popover-inner {
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.appointment-popover .ant-popover-title {
  font-weight: 600;
  border-bottom: 1px solid #f0f0f0;
  padding: 12px 16px;
  font-size: 14px;
}

.appointment-popover .ant-popover-inner-content {
  padding: 12px 16px;
}

.appointment-popover .ant-popover-arrow {
  z-index: 1;
  display: block !important;
}

/* Ensure popover is visible in all calendar views */
.fc-view-harness {
  overflow: visible !important;
}



/* Appointment modal styling */
.appointment_modal > .ant-modal-content {
  padding: 0 !important;
  border-radius: 12px;
  overflow: hidden;
}

.appointment_box {
  display: flex;
  min-height: 600px;
}

.appointment_box > .left {
  width: 35%;
  display: flex;
  flex-direction: column;
  background-color: #1890ff;
  background-image: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
  justify-content: center;
  align-items: center;
  border-radius: 0;
  padding: 2rem 1rem;
  color: white;
}

.appointment_box > .left > p, h5 {
  color: white;
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
}

.appointment_box > .left > p {
  font-size: 13px;
}

.appointment-form {
  width: 65%;
  padding: 24px 32px;
}

.right-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 24px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
}

.right-header > p {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0;
}



.appointment-form .ant-input:hover,
.appointment-form .ant-select-selector:hover,
.appointment-form .ant-picker:hover {
  border-color: #40a9ff;
}

.appointment-form .ant-input:focus,
.appointment-form .ant-select-selector:focus,
.appointment-form .ant-picker-focused {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.appointment-form .ant-btn {
  border-radius: 6px;
  height: 38px;
  font-weight: 500;
}

.appointment-form .ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.appointment-form .ant-btn-primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.danger__button {
  background-color: #fff;
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.danger__button:hover {
  background-color: #fff1f0;
  border-color: #ff7875;
  color: #ff7875;
}

/* Tooltip icon */
.tooltip__icon {
  color: #bfbfbf;
  margin-left: 4px;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .appointment_box {
    flex-direction: column;
  }
  
  .appointment_box > .left,
  .appointment-form {
    width: 100%;
  }
  
  .appointment_box > .left {
    border-radius: 0;
    padding: 1.5rem 1rem;
  }
  
  .fc .fc-toolbar {
    justify-content: center;
  }
  
  .fc .fc-toolbar-title {
    font-size: 1.2rem !important;
    text-align: center;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  .fc-button {
    padding: 0.4rem 0.8rem !important;
    font-size: 0.9rem !important;
  }
}

/* Accessibility improvements */
.fc a:focus,
.fc button:focus {
  outline: 2px solid #1890ff;
  outline-offset: 2px;
}

/* High contrast mode support */
@media (forced-colors: active) {
  .fc-button-primary:not(:disabled).fc-button-active,
  .fc-button-primary:not(:disabled):active {
    border: 2px solid CanvasText !important;
  }
  
  .fc-event {
    border: 1px solid CanvasText !important;
  }
}

/* Feedback message styling */
.feedback-message {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1050;
  animation: slideIn 0.3s ease-out forwards, fadeOut 0.3s ease-in 2.7s forwards;
  max-width: 400px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.feedback-message.success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a;
}

.feedback-message.error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  color: #ff4d4f;
}

.feedback-message.warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  color: #faad14;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Calendar loading overlay */
.calendar-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 12px;
}

/* Date click feedback animation */
.date-click-feedback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: rgba(24, 144, 255, 0.2);
  border-radius: 50%;
  animation: pulse 0.3s ease-out;
  pointer-events: none;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

/* Status indicators for events */
.status-indicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: middle;
}

.status-indicator.confirmed {
  background-color: #52c41a;
}

.status-indicator.cancelled {
  background-color: #ff4d4f;
}

.status-indicator.rescheduled {
  background-color: #faad14;
}

.status-indicator.completed {
  background-color: #1890ff;
}

/* Event status styling */
.fc-event-main-wrapper.status-confirmed {
  border-left: 3px solid #52c41a;
}

.fc-event-main-wrapper.status-cancelled {
  border-left: 3px solid #ff4d4f;
  opacity: 0.7;
}

.fc-event-main-wrapper.status-rescheduled {
  border-left: 3px solid #faad14;
}

.fc-event-main-wrapper.status-completed {
  border-left: 3px solid #1890ff;
}

/* Form actions styling */
.form-actions {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.mr-2 {
  margin-right: 12px;
}

/* Improved focus states for accessibility */
input:focus, 
select:focus, 
textarea:focus, 
button:focus {
  outline: 2px solid #1890ff;
  outline-offset: 2px;
}

/* Improved hover states */
.fc-daygrid-day:hover .fc-daygrid-day-number {
  font-weight: 500;
}

/* Today indicator styling */
.fc .fc-timegrid-now-indicator-line {
  margin: 0 -1px;
  border-width: 2px;
}

/* Empty state styling */
.fc-view-harness:empty::before {
  content: "No appointments scheduled";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #8c8c8c;
  font-size: 16px;
}

/* Responsive improvements */
@media (max-width: 576px) {
  .fc-header-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .fc-toolbar-chunk {
    margin-bottom: 8px;
  }
  
  .feedback-message {
    max-width: 90%;
    left: 5%;
    right: 5%;
  }
}

/* Print styles for calendar */
@media print {
  .calender {
    box-shadow: none;
    padding: 0;
  }
  
  .fc-button-primary,
  .calendar-loading-overlay,
  .feedback-message {
    display: none !important;
  }
  
  .fc-view-harness {
    height: auto !important;
  }
  
  .fc-event {
    box-shadow: none;
    border: 1px solid #d9d9d9 !important;
  }
}

/* Event service styling */
.fc-event-service {
  font-size: 10px;
  opacity: 0.8;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: italic;
}

/* Improved event time display */
.fc-event-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}



/* Calendar header improvements */
.fc-toolbar-title {
  position: relative;
  padding-bottom: 8px;
}

.fc-toolbar-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background-color: #1890ff;
  border-radius: 3px;
}

/* Calendar day header styling */
.fc-col-header-cell {
  padding: 8px 0;
  font-weight: 600;
}

.fc-col-header-cell-cushion {
  padding: 6px;
  color: #333;
}

/* Weekend day styling */
.fc-day-sat, .fc-day-sun {
  background-color: rgba(0, 0, 0, 0.02);
}

/* More events indicator styling */
.fc-daygrid-more-link {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 2px 6px;
  margin: 2px 4px;
  font-size: 11px;
  font-weight: 500;
  color: #1890ff;
}

.fc-daygrid-more-link:hover {
  background-color: #e6f7ff;
  color: #096dd9;
}

/* Empty day styling */
.fc-daygrid-day-events:empty::before {
  content: "";
  display: block;
  height: 4px;
  margin: 4px 0;
}

/* Improve time column in week view */
.fc-timegrid-axis {
  width: 60px !important;
  font-size: 12px;
  font-weight: 500;
  color: #666;
}

/* Improve all-day section */
.fc-timegrid-axis-cushion {
  padding: 8px 4px;
}

.fc-timegrid-slot-label {
  padding: 4px;
}

/* Calendar toolbar improvements */
.fc-toolbar {
  position: relative;
}

/* Calendar navigation buttons */
.fc-prev-button, .fc-next-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

/* Improve mobile experience */
@media (max-width: 768px) {
  .fc-toolbar-chunk {
    margin-bottom: 10px;
  }
  
  .fc-toolbar-title {
    font-size: 1.2rem !important;
  }
  
  .fc-col-header-cell-cushion {
    padding: 4px;
  }
  
  .fc-daygrid-day-number {
    padding: 4px !important;
  }
  
  .fc-event {
    margin: 1px 0;
  }
  
  .fc-timegrid-axis {
    width: 40px !important;
    font-size: 10px;
  }
}

/* Improve dark mode support */
@media (prefers-color-scheme: dark) {
  .fc-theme-standard .fc-scrollgrid {
    border-color: #303030;
  }
  

  

  
  .fc-day-today {
    background-color: rgba(24, 144, 255, 0.1) !important;
  }
  

  

}

/* Drag and drop styling */
.fc-event {
  cursor: grab !important;
}

.fc-event:active {
  cursor: grabbing !important;
}

/* Resize handle styling */
.fc-event .fc-event-resizer {
  display: block;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: s-resize;
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* .fc-event .fc-event-resizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
} */

/* .fc-event:hover .fc-event-resizer {
  background-color: rgba(0, 0, 0, 0.1);
} */

/* Visual feedback during drag */
.fc-event-dragging {
  opacity: 0.8 !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2) !important;
}

/* Visual feedback during resize */
.fc-event-resizing {
  opacity: 0.8 !important;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2) !important;
}

/* Highlight drop target */
.fc-highlight {
  /* background-color: rgba(24, 144, 255, 0.15) !important; */
  border: 2px dashed rgba(24, 144, 255, 0.4) !important;
}

/* Tooltip for drag and resize */
.fc-event::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
}

/* Add a subtle animation for drag start */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

.fc-event-dragging {
  animation: pulse 0.5s infinite;
}

/* Improve mobile drag and drop experience */
@media (max-width: 768px) {
  .fc-event {
    padding: 6px !important;
  }
  
  .fc-event .fc-event-resizer {
    height: 12px;
  }
}

/* List view popover styling */
.list-view-popover {
  position: fixed !important;
  z-index: 1100 !important;
}

.fc-list-event .fc-list-event-title {
  position: relative;
  cursor: pointer;
  padding: 4px 0;
}

/* .fc-list-event:hover {
  background-color: #f0f7ff !important;
} */

/* Ensure list view popovers are visible */
.fc-list-table {
  position: relative;
  z-index: 1;
}

.fc-list-table .fc-scroller {
  overflow: visible !important;
}

.fc-list-event-title {
  position: relative;
  z-index: 2;
}

/* Fix popover arrow position for list view */
.list-view-popover .ant-popover-arrow {
  display: block !important;
}

/* Ensure popover is above all other elements */
.ant-popover {
  z-index: 1100 !important;
}

/* Enhanced list view event styling */
.fc-list-event-with-popover {
  position: relative;
  transition: background-color 0.2s;
}

.fc-list-event-with-popover:hover {
  background-color: #f0f7ff !important;
}

.fc-list-event-with-popover .fc-list-event-title {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
}

.fc-list-event-with-popover .fc-list-event-title:hover {
  background-color: rgba(24, 144, 255, 0.1);
}

/* Make sure list view dot is visible */
.fc-list-event-dot {
  display: inline-block !important;
  margin-right: 6px !important;
}

/* Month view popover styling */
.fc-month-event-with-popover {
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
}



/* Ensure month view events are visible */


/* Fix popover positioning in month view */


/* Ensure popover is above other elements */
.ant-popover {
  z-index: 1100 !important;
}

/* Fix popover arrow position in month view */
.fc-month-event-with-popover .ant-popover-arrow {
  display: block !important;
}

/* Ensure month view can show popovers */
.fc-daygrid-day-frame {
  overflow: visible !important;
}

/* Fix for month view "more" link */
.fc-daygrid-more-link {
  cursor: pointer;
  position: relative;
  z-index: 5;
}

/* Ensure popover container has proper z-index */
.fc-popover {
  z-index: 1000 !important;
}


/* Fix for "more" popover in month view */
.fc-more-popover {
  z-index: 1050 !important;
}

.fc-more-popover .fc-popover-body {
  /* max-height: 60vh; */
  overflow-y: auto;
}

/* Make sure the "more" events link is visible and clickable */
.fc-daygrid-more-link {
  font-weight: bold;
  color: #1890ff;
  padding: 2px;
  margin-top: 1px;
}

.fc-daygrid-more-link:hover {
  background-color: rgba(24, 144, 255, 0.1);
  border-radius: 2px;
}




.fc-daygrid-event-harness:hover,
.fc-event-hovered {
  z-index: 1000 !important;
}




/* Fix for day grid events to ensure popovers are visible */
.fc-daygrid-day-events {
  position: relative !important;
  z-index: 1 !important;
  overflow: visible !important;
}

/* Ensure event content is visible */
.fc-event-main {
  position: relative !important;
  z-index: 1 !important;
}

/* Fix for event title to ensure it's visible */
.fc-event-title-container {
  position: relative !important;
  z-index: 1 !important;
}

